<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-rulesFrom"
      style="height: 100%">
      <el-dialog v-dialogDrag :title="title" :visible.sync="dialogVisible" :before-close="handleClose"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <el-col :span="8">
            <el-form-item type="type" label="招工城市-省" prop="province">
              <el-select :disabled="disableds" v-model="ruleForm.province" placeholder="省" class="selectClass"
                @change="provinceChange">
                <el-option v-for="(item,i) in provincelist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item type="type" label="招工城市-市" prop="city">
              <el-select :disabled="disableds" clearable v-model="ruleForm.city" placeholder="市" class="selectClass"
                @change="cityChange">
                <el-option v-for="(item,i) in citylist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item type="type" label="招工城市-县" prop="county">
              <el-select :disabled="disableds" clearable v-model="ruleForm.county" placeholder="县" class="selectClass">
                <el-option v-for="(item,i) in countylist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item type="type" label="招工内容" prop="content">
              <el-input :disabled="disableds" v-model.trim="ruleForm.content" type="textarea" :rows="3"
                placeholder="招工内容" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="薪酬" prop="payMoney">
              <el-input :disabled="disableds" v-model="ruleForm.payMoney" placeholder="薪酬" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="电话" prop="phone">
              <el-input :disabled="disableds" v-model.number="ruleForm.phone" placeholder="电话" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item type="type" label="工种" prop="workerTypeTwo">
              <el-cascader v-model="ruleForm.workerTypeTwo" :props="regionParams" :options="options" @change="handleChange"></el-cascader>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item type="type" label="一级工种" prop="workerTypeOne">
              <el-input :disabled="disableds" v-model.trim="ruleForm.workerTypeOne" placeholder="一级工种" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="二级工种" prop="workerTypeTwo">
              <el-input :disabled="disableds" v-model.trim="ruleForm.workerTypeTwo" placeholder="二级工种" />
            </el-form-item>
          </el-col> -->









        </el-row>

        <span slot="footer" class="dialog-footer">
          <el-button v-if="!disableds" type="primary" @click="deteRmine('ruleForm')">确 定</el-button>
          <el-button @click="cancel">关 闭</el-button>
        </span>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
  import cityData from '@/common/js/city_code.json';
  import {
    grecruitmentgetsave,
    grecruitmentgetupdate,
    getTypeWorkListByPage
  } from "@/api/api";
  export default {
    name: "",
    components: {},
    props: {
      dialogdata: Array,
      title: String,
    },
    data() {
      return {
        dialogVisible: true,
        disableds: false,
        provincelist: [],
        citylist: [],
        countylist: [],
        options: [],
        regionParams: {
          label: 'typeName', //这里可以配置你们后端返回的属性
          value: 'typeName',
          children: 'jobList',
          expandTrigger: 'click',
          checkStrictly: false,
        }, //地域信息配置参数

        row: "",
        dialogState: "",
        ruleForm: {
          groupArea: '',
          province: "", //省
          city: '', //市
          county: '', //县
          content: "", //招工内容
          payMoney: '', //薪酬
          phone: '', //电话
          workerTypeOne: '', //一级工种
          workerTypeTwo: '', //二级工种
        },
        rules: {
          province: [{
            required: true,
            message: "请输入必填项",
            trigger: "change",
          }, ],
          content: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          payMoney: [{
            required: true,
            message: "请输入排序号",
            trigger: "blur",
          }],
          phone: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          workerTypeOne: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          workerTypeTwo: [{
            required: false,
            message: "请输入必填项",
            trigger: "blur",
          }, ],

        },

      };
    },
    computed: {},
    watch: {},
    created() {},
    beforeDestroy() {},
    mounted() {
      this.provincelist = cityData;
      if (this.dialogdata.length == 2) {
        this.dialogState = this.dialogdata[1];
        this.row = this.dialogdata[0];
        this.ruleForm.content = this.row.content;
        console.log(this.row)
        this.ruleForm.payMoney = this.row.payMoney;
        this.ruleForm.phone = this.row.phone;
        this.ruleForm.workerTypeOne = this.row.workerTypeOne;
        this.ruleForm.workerTypeTwo = this.row.workerTypeTwo;
        this.ruleForm.province = this.row.groupArea.split('-')[0];
        this.ruleForm.city = this.row.groupArea.split('-')[1];

      }
      let params = {
        pageIndex: 1,
        pageSize: 1000,

      }

      getTypeWorkListByPage(params).then((res) => {
        console.log(res)
        if (res.status == 200) {

         this.options = res.data.records;
        } else {
          this.$message.error(res.status);
        }
      }).catch((error) => {
        this.$message.error("工种获取失败！");
      });

    },
    methods: {
      handleChange() {

      },
      deteRmine(fromName) {
        this.$refs[fromName].validate((valid) => {
          if (valid) {
            /* if (this.ruleForm.province != '') {
              this.ruleForm.groupArea = this.ruleForm.province
            }
            if (this.ruleForm.city != '') {
              this.ruleForm.groupArea = this.ruleForm.city
            }
            if (this.ruleForm.county != '') {
              this.ruleForm.groupArea = this.ruleForm.county
            } */
            let addressData = ''
            if(this.ruleForm.city){
              addressData = this.ruleForm.province +'-'+ this.ruleForm.city
            }else{
              addressData = this.ruleForm.province
            }


            if (this.dialogState != "edit") {//+'-'+this.ruleForm.county
              let params = {
                groupArea:addressData,
                content: this.ruleForm.content, //招工内容
                payMoney: this.ruleForm.payMoney, //薪酬
                phone: this.ruleForm.phone, //电话
                workerTypeOne: this.ruleForm.workerTypeOne, //一级工种
                workerTypeTwo: this.ruleForm.workerTypeTwo[1], //二级工种
              };
              grecruitmentgetsave(params).then((res) => {
                if (res.status == 200) {
                  this.$message({
                    message: "添加成功！",
                    type: "success",
                  });
                  this.$emit("addetermine", {});
                } else {
                  this.$message.error(res.msg);
                }
              }).catch((error) => {
                this.$message.error("新增失败！");
              });
            } else {
              let params = {
                groupArea:addressData,
                content: this.ruleForm.content, //招工内容
                payMoney: this.ruleForm.payMoney, //薪酬
                phone: this.ruleForm.phone, //电话
                workerTypeOne: this.ruleForm.workerTypeOne, //一级工种
                workerTypeTwo: this.ruleForm.workerTypeTwo, //二级工种
                id: this.row.id,
              };
              grecruitmentgetupdate(params).then((res) => {
                if (res.status == 200) {
                  this.$message({
                    message: "编辑成功！",
                    type: "success",
                  });
                  this.$emit("addetermine", {});
                } else {
                  this.$message.error(res.msg);
                }
              }).catch((error) => {
                this.$message.error("编辑失败！");
              });
            }
          } else {
            return false;
          }
        });
      },
      provinceChange(val) {
        this.ruleForm.city = ''
        this.ruleForm.county = ''

        for (let i = 0; i < this.provincelist.length; i++) {
          if (val == this.provincelist[i].name) {
            this.citylist = this.provincelist[i].city
          }
        }


      },
      cityChange(val) {
        this.ruleForm.county = '';

        for (let i = 0; i < this.citylist.length; i++) {
          if (val == this.citylist[i].name) {
            this.countylist = this.citylist[i].area
          }
        }
      },
      cancel() {
        this.dialogVisible = false;
        this.$emit("getcancel");
      },

      handleClose() {
        this.dialogVisible = false;
        this.$emit("getcancel");
      },
    },
  };
</script>

<style scoped>
  .selectClass {
    width: 100%;
  }

  ::v-deep .el-dialog {
    width: 700px !important;
    border-radius: 8px;
    overflow: hidden;
  }

  ::v-deep .el-dialog__body {
    max-height: 500px;
    overflow: auto;
  }

  .rowTops {
    position: relative;
  }

  .rowTopa {
    position: absolute;
    left: 0px;
    top: 40px;
    right: 0px;
    margin: auto;
    max-height: 300px;
    background: #fff;
    border: 1px solid #e3e3e3;
    z-index: 100;
    border-radius: 5px;
    overflow: auto;
    padding-bottom: 10px;
  }

  .iptclose {
    position: absolute;
    right: 10px;
    top: 0px;
    color: #606266;
    font-size: 16px;
    line-height: 40px;
    z-index: 1000;
    transition: color 0.15s linear;
  }

  .iptclose:hover {
    color: #5cb6ff !important;
  }
</style>
