<template>
  <div class="container">
    <div class="query-title">

      <div class="demo-input-suffix">

        <p class="titname">招工信息列表</p>

        <el-button type="primary" class="tit-button" @click="addBtn">新 增</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table highlight-current-row :header-cell-style="{ background: 'rgba(113,183,255,0.4)', color: '#606266', }"
        :data="tabledata" :height="tableHeight" border style="width: 100%">
        <el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
        <el-table-column prop="groupArea" label="招工地点" align="center"></el-table-column>
        <el-table-column prop="content" label="招工内容" align="center"></el-table-column>
        <el-table-column prop="payMoney" label="薪酬" align="center"></el-table-column>
        <el-table-column prop="phone" label="电话" align="center"></el-table-column>
        <el-table-column prop="workerTypeOne" label="一级工种" align="center"></el-table-column>
        <el-table-column prop="workerTypeTwo" label="二级工种" align="center"></el-table-column>
        <el-table-column prop="" label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button class="tabBnt" type="text" @click="edit(scope.row, scope.$index)">编辑</el-button>
            <el-button class="tabBnt" type="text" @click="deletes(scope.row, scope.$index)">删除</el-button>
            <el-button v-if="scope.row.state==0" class="tabBnt" type="text" @click="Lowershelf(scope.row, scope.$index)">下架</el-button>
            <el-button v-if="scope.row.state==1" class="tabBnt" type="text" @click="Lowershelf(scope.row, scope.$index)">上架</el-button>

            <!--<el-dropdown trigger="click">
							<span class="el-dropdown-link"><i class="el-icon-arrow-down el-icon-setting"></i></span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="edit(scope.row,scope.$index)">编辑</el-dropdown-item>
								<el-dropdown-item @click.native="deletes(scope.row,scope.$index)">删除</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Dialog v-if="dialogVisible == true" :title="title" :dialogdata="dialogData" @getcancel="Getcancel"
      @addetermine="addeteRmine"></Dialog>
    <div class="fye">
			<pagintion :ToTal="total" :pageNums="pagenums" @dsydata="DsyData" @dstdata="DstData" />
		</div>
  </div>
</template>

<script>
  import Dialog from "./dialogPopup.vue";
  import pagintion from "@/components/pagintion/pagintion.vue";
  import {
    grecruitmentgetList,
    grecruitmentgetdelete,
    grecruitmentdoStanding
  } from "@/api/api";
  export default {
    name: "recruitmentInfo",
    components: {
      Dialog,
      pagintion,
    },
    props: {},
    data() {
      return {
        specimenname: "",
        tabledata: [],
        tableHeight: window.innerHeight - 235,
        dialogData: [],

        pageNum: 1,
        pageSize: 20,

        total: null,
        pagenums: null,
        dialogVisible: false,
        title: "",
      };
    },
    computed: {},
    watch: {},
    created() {
      window.addEventListener('resize', this.getHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.getHeight)
    },
    beforeDestroy() {},
    mounted() {

      this.Datalist();
    },
    methods: {
      Backbtn() {
        this.$router.go(-1)
      },
      getHeight() {
        this.tableHeight = window.innerHeight - 235;
      },

      addBtn() {
        this.dialogData = [];
        this.dialogVisible = true;
        this.title = "新增招工信息";
      },
      Getcancel() {
        this.dialogVisible = false;
      },
      addeteRmine() {
        this.dialogVisible = false;
        this.Datalist();
      },
      DsyData(data) {
        this.pagenums = data.dsy;
        this.pageNum = data.dsy;
        this.Datalist()
      },
      DstData(data) {
        this.pageSize = data.dst;
        this.Datalist()
      },

      edit(row, index) {
        this.dialogVisible = true;
        this.dialogData = [row, "edit"];
        this.title = "编辑招工信息";
      },
      Lowershelf(row){
        let tips = ''
          if(row.state==0){
            tips = '确定要下价此招工信息？ 是否继续?'
          }else{
            tips = '是否上架此招工信息？ 是否继续?'
          }
          this.$confirm(tips,"提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then((action) => {
            if (action === "confirm") {
              let params = {id:row.id}
              grecruitmentdoStanding(params).then((res) => {
                if (res.status == 200) {
                  this.Datalist();
                  this.$message({
                    message: "成功",
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                }
              }).catch((err) => {
                this.$message.error("删除失败！");
              });
            }
          }).catch(() => {});



      },
      deletes(row) {
        this.$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = [row.id]
            grecruitmentgetdelete(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("删除失败！");
            });
          }
        }).catch(() => {});

      },

      Datalist() {
        let params = {
            size:this.pageSize,
            current:this.pageNum
        }
        grecruitmentgetList(params).then((res) => {
          this.tabledata = res.data.list;
         // this.tabledata = res.data;
          this.total = Number(res.data.total);

        }).catch((error) => {
          this.$message.error("获取数据失败！");
        });
      },
    },
  };
</script>

<style scoped>

</style>
